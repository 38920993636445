<template>
  <div class="container"><br>  
    <div class="col-12 text-center g">
        <h4>
            <img :src="href + '/files/weeklyplan.png'" alt="" style="width: 40px">
            خطة التعلم الاسبوعية
            <br>
            <small>اداة نقار المساعد الذكي</small>
        </h4>
    </div>
    <div class="card alert alert-danger g text-center" style="border: 2px solid #888; border-radius: 20px" v-if="noshow">
        {{ noshow }}
    </div>
    <div class="card" style="border: 2px solid #888; border-radius: 20px" v-if="!noshow">
        <div class="card-header text-center">
            <span id="fromto">🗓️ {{ arr.week }}</span> 👤 {{ classroom }}
        </div>
        <div class="card-body" style="margin-top: -20px" v-if="done">
            <br>
            <app-collapse>
                <app-collapse-item v-for="(data, teacher, i) in arr.arr" :key="teacher" class="itemm" :title="'👤 ' + data.name" :id="`teacher_${i}`">
                    <div class="col-12 table-responsive">
                        <table class="table table-sm table-bordered table-striped table-hover bg-white">
                            <thead>
                                <th>
                                    المادة
                                </th>
                                <th>
                                    
                                </th>
                                <th>
                                    الأحد
                                    <br>
                                    <span>{{ arr.dates[0] }}</span>
                                </th>
                                <th>
                                    الاثنين
                                    <br>
                                    <span>{{ arr.dates[1] }}</span>
                                </th>
                                <th>
                                    الثلاثاء
                                    <br>
                                    <span>{{ arr.dates[2] }}</span>
                                </th>
                                <th>
                                    الاربعاء
                                    <br>
                                    <span>{{ arr.dates[3] }}</span>
                                </th>
                                <th>
                                    الخميس 
                                    <br>
                                    <span>{{ arr.dates[4] }}</span>
                                </th>
                            </thead>
                            <tbody>
                                <template v-for="(subject) in subjectsNames(data, teacher)">
                                    <tr :key="subject">
                                        <td rowspan="3" style="padding: 0px" class="text-center">
                                            {{ subject }}
                                        </td>
                                        <td>الدرس</td>
                                        <td v-if="checkT(teacher, arr.dates[0], subject)">
                                            {{ arr.arr[teacher].days[arr.dates[0]][subject].Title }}
                                        </td>
                                        <td v-if="checkT(teacher, arr.dates[1], subject)">
                                            {{ arr.arr[teacher].days[arr.dates[1]][subject].Title }}
                                        </td>
                                        <td v-if="checkT(teacher, arr.dates[2], subject)">
                                            {{ arr.arr[teacher].days[arr.dates[2]][subject].Title }}
                                        </td>
                                        <td v-if="checkT(teacher, arr.dates[3], subject)">
                                            {{ arr.arr[teacher].days[arr.dates[3]][subject].Title }}
                                        </td>
                                        <td v-if="checkT(teacher, arr.dates[4], subject)">
                                            {{ arr.arr[teacher].days[arr.dates[4]][subject].Title }}
                                        </td>
                                    </tr>
                                    <tr :key="subject + '1'">
                                        <td style="text-align: center">الاهداف</td>
                                        <td v-if="checkT(teacher, arr.dates[0], subject)">
                                            <span v-html="arr.arr[teacher].days[arr.dates[0]][subject].goal"></span>
                                        </td>
                                        <td v-if="checkT(teacher, arr.dates[1], subject)">
                                            <span v-html="arr.arr[teacher].days[arr.dates[1]][subject].goal"></span>
                                        </td>
                                        <td v-if="checkT(teacher, arr.dates[2], subject)">
                                            <span v-html="arr.arr[teacher].days[arr.dates[2]][subject].goal"></span>
                                        </td>
                                        <td v-if="checkT(teacher, arr.dates[3], subject)">
                                            <span v-html="arr.arr[teacher].days[arr.dates[3]][subject].goal"></span>
                                        </td>
                                        <td v-if="checkT(teacher, arr.dates[4], subject)">
                                            <span v-html="arr.arr[teacher].days[arr.dates[4]][subject].goal"></span>
                                        </td>
                                    </tr>
                                    <tr :key="subject + '2'">
                                        <td style="text-align: center">الواجبات</td>
                                        <td v-if="checkT(teacher, arr.dates[0], subject)">
                                            <span v-html="arr.arr[teacher].days[arr.dates[0]][subject].homeworks"></span>
                                        </td>
                                        <td v-if="checkT(teacher, arr.dates[1], subject)">
                                            <span v-html="arr.arr[teacher].days[arr.dates[1]][subject].homeworks"></span>
                                        </td>
                                        <td v-if="checkT(teacher, arr.dates[2], subject)">
                                            <span v-html="arr.arr[teacher].days[arr.dates[2]][subject].homeworks"></span>
                                        </td>
                                        <td v-if="checkT(teacher, arr.dates[3], subject)">
                                            <span v-html="arr.arr[teacher].days[arr.dates[3]][subject].homeworks"></span>
                                        </td>
                                        <td v-if="checkT(teacher, arr.dates[4], subject)">
                                            <span v-html="arr.arr[teacher].days[arr.dates[4]][subject].homeworks"></span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </app-collapse-item>
            </app-collapse>
        </div>
        <textarea class='get-local-storage' name="teachers" style='display: none'></textarea>
    </div><br><br>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    AppCollapse,
    AppCollapseItem,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            arr: {},
            titles: {},
            done: false,
            saving: false,
            href: window.location.href.split('/weeklyplan/')[0],
            school_id: window.location.href.split("weeklyplan/")[1].split("/")[0],
            classroom: decodeURIComponent(window.location.href.split("weeklyplan/")[1].split("/")[1]),
            noshow: false,
            days: ["الاحد",'الاثنين','الثلاثاء','الاربعاء','الخميس']
        }
    },
    created(){
        var g = this;
        $.post(api + "/api/reports/parent/weeklyplan/get", {
            school_id: g.school_id,
            classroom: g.classroom
        }).then(function(r){
            if(r.status != 100){
                g.noshow = r.response;
                return;
            }
            g.done = true;
            g.arr = r.response;
        }).fail(function(){
        g.noshow = "حدث خطأ في الاتصال";
        return;})
    },
    methods: {
        subjectsNames(r, teacher){
            var arr = [], g = this;
            for (const [key, valu] of Object.entries(r)) {
                for (const [key, valuex] of Object.entries(valu)) {
                for (const [key2, valuex2] of Object.entries(valuex)) {
                    if(!arr.includes(key2) && key2 != "0"){
                        arr.push(key2?.trim())
                    }
                }
            }
            }
            return arr;
        },
        checkT(a, b, c){
            var g = this;
            if(!g.arr.arr[a]){
                g.arr.arr[a] = {days: {}, name: "-"}
            }
            if(!g.arr.arr[a].days[b]){
                g.arr.arr[a].days[b] = {}
            }
            if(!g.arr.arr[a].days[b][c]){
                g.arr.arr[a].days[b][c] = {Title: "", goal: "", homeworks: ""}
            }
            return true
        }
    }
}
</script>

<style>
#date2{
    display: none;
}
th,td{
    text-align: center;
}
[aria-expanded]:hover{
    background: #eee !important;
}
body{
    background: #fafafa !important;
}
@media only screen and (max-device-width: 640px) {
 
    td { white-space: nowrap !important; }
}
</style>